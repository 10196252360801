
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { BiblioteksentralenProvider } from "@biblioteksentralen/js-utils";
import { storeFeatureFlags } from "@libry-content/common";
import { Analytics } from "@vercel/analytics/react";
import { AppProps } from "next/app";
import { ReactNode, useState } from "react";
import Testenvironmentbanner from "../components/TestEnvironmentBanner";
import { EditInSanityContextProvider } from "../components/editInSanity/EditInSanityContext";
import { SOMEPreview } from "../components/preview/SOMEPreview";
import { PreviewContextProvider } from "../components/preview/previewContext";
import { PlausibleSetup } from "../utils/analytics/Plausible";
import { useLogDevInfo } from "../utils/logDevInfo";
// Polyfill for Intl.PluralRules; https://github.com/aralroca/next-translate/issues/508#issuecomment-778663166
// Note that this should be taken care of by next by default targeting safari 12, but that doesn't seem
// to work: https://nextjs.org/docs/basic-features/supported-browsers-features#browserslist
import "intl-pluralrules";
import ErrorBoundary from "../components/errorPages/ErrorBoundary";
import { customTheme } from "../utils/customTheme";
function Providers(props: {
    children: ReactNode;
}) {
    return (<PreviewContextProvider>
      <BiblioteksentralenProvider customTheme={customTheme}>
        <EditInSanityContextProvider>{props.children}</EditInSanityContextProvider>
      </BiblioteksentralenProvider>
    </PreviewContextProvider>);
}
function App({ Component, pageProps }: AppProps) {
    // storeFeatureFlags should run only once but before other useEffect hooks; useMount and useEffect are too late.
    // useLayoutEffect works but gives an error when running on server, so we use an initial value function with useState.
    useState(() => storeFeatureFlags());
    useLogDevInfo();
    return (
    // https://nextjs.org/docs/advanced-features/error-handling#handling-client-errors
    <ErrorBoundary boundaryName="App" isFullPage>
      <Providers>
        <PlausibleSetup />
        <Testenvironmentbanner />
        <Component {...pageProps}/>
        <SOMEPreview />
      </Providers>
      <Analytics />
    </ErrorBoundary>);
}
const __Next_Translate__Page__18848d1b3ea__ = App;

    export default __appWithI18n(__Next_Translate__Page__18848d1b3ea__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  