import {
  DigitalLibrary,
  DigitalLibraryService,
  DigitalLibraryServiceTemplateContent,
  Modify,
} from "@libry-content/types";
import { groq } from "next-sanity";

export const digitalLibraryFilter = groq`_type == "digitalLibrary" && count(services[defined(_type)]) == 1`;

// NB! This resolver needs access to global documents, so dont use within fetchWithCommonDataAndCurrentSiteFilter which automatically scopes all queries to current site
export const resolveDigitalLibraryService = groq`
  ...,
  "services": null,
  ...services[defined(_type)][0] {
    ...,
    "globalServiceTemplate": *[digitalLibraryService == ^._type][0],
    // These are defined as strings in schema in order to get the right studio layout
    "canLogInWithFeide": select(canLogInWithFeide == "true" => true, false),
    "canBeUsedWithSelfService": select(canBeUsedWithSelfService == "true" => true, false)
  }
`;

export type ResolvedDigitalLibraryService = Omit<DigitalLibrary, "_type" | "services"> &
  Modify<
    DigitalLibraryService,
    {
      canLogInWithFeide: boolean;
      canBeUsedWithSelfService: boolean;
      globalServiceTemplate: DigitalLibraryServiceTemplateContent | undefined;
    }
  >;

export const customDigitalLibraryServiceFilter = groq`
  _type == "digitalLibrary" && count(services[_type=="digitalLibraryServiceCustom"]) == 1
`;
