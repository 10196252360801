import { DigitalLibraryService, Documents, objectKeys } from "@libry-content/types";

export type EditableType =
  | Extract<
      Documents["_type"],
      | "site"
      | "library"
      | "event"
      | "service"
      | "list"
      | "staticPage"
      | "privacyPolicy"
      | "footerMenuCollection"
      | "sommerles"
      | "alert"
      | "recommendation"
      | "adventCalendar"
    >
  | DigitalLibraryService["_type"];

// Use a record in order to make sure all types are included through ts
const editableTypesRecord: Record<EditableType, true> = {
  site: true,
  library: true,
  event: true,
  service: true,
  list: true,
  staticPage: true,
  privacyPolicy: true,
  footerMenuCollection: true,
  sommerles: true,
  alert: true,
  recommendation: true,
  adventCalendar: true,
  digitalLibraryServiceBibliofilApp: true,
  digitalLibraryServiceBookbites: true,
  digitalLibraryServiceVerdensbiblioteket: true,
  digitalLibraryServiceDigitaltNasjonaltLanekort: true,
  digitalLibraryServiceBibliotekSok: true,
  digitalLibraryServiceCustom: true,
};

const EDITABLE_TYPES = objectKeys(editableTypesRecord);

export const isEditableType = (typeName: string): typeName is EditableType =>
  (EDITABLE_TYPES as string[]).includes(typeName);

export type EditableDocument = { _id: string; _type: EditableType };

export const getEditDocumentUrl = (doc: EditableDocument) => {
  switch (doc._type) {
    case "site":
      return `/cms/desk/site;site`;
    case "privacyPolicy":
      return `/cms/desk/site;privacyPolicy`;
    case "footerMenuCollection":
      return `/cms/desk/site;footerMenuCollection`;
    case "alert":
      return `/cms/desk/alert`;
    case "sommerles":
      return `/cms/desk/sommerles`;
    case "adventCalendar":
      return `/cms/desk/adventCalendar`;
    default:
      return `/cms/intent/edit/id=${doc._id}`;
  }
};

export type CreateNewableType = Exclude<
  EditableType,
  "site" | "privacyPolicy" | "footerMenuCollection" | "sommerles" | "alert" | "adventCalendar"
>;

// Use a record in order to make sure all types are included through ts
const createNewableTypesRecord: Record<CreateNewableType, true> = {
  library: true,
  event: true,
  service: true,
  list: true,
  staticPage: true,
  recommendation: true,
  digitalLibraryServiceBibliofilApp: true,
  digitalLibraryServiceBookbites: true,
  digitalLibraryServiceVerdensbiblioteket: true,
  digitalLibraryServiceDigitaltNasjonaltLanekort: true,
  digitalLibraryServiceBibliotekSok: true,
  digitalLibraryServiceCustom: true,
};

const CREATE_NEWABLE_TYPES = objectKeys(createNewableTypesRecord);

export const isCreateNewableType = (typeName: string): typeName is CreateNewableType =>
  (CREATE_NEWABLE_TYPES as string[]).includes(typeName);

export const getCreateDocumentUrl = (type: CreateNewableType, newDocumentId: string) => {
  // Template-delen må enkodes for å fungere med forhåndsvisning
  const urlEncodedTemplate = encodeURIComponent(`,template=${type}`);
  return `/cms/desk/${type};${newDocumentId}${urlEncodedTemplate}`;
};
