import { formaterDato, slugifyString } from "@biblioteksentralen/js-utils";
import { LocalizedField } from "@libry-content/localization";
import {
  DigitalLibraryServiceTemplate,
  Documents,
  Employee,
  isDigitalLibraryCustomService,
  Library,
  List,
  Recommendation,
  Service,
  StaticPage,
} from "@libry-content/types";
import kebabCase from "lodash/kebabCase";
import { GetStaticPropsContext } from "next";
import { NextEvent, ResolvedEvent } from "../components/arrangement/sanityQuery";
import { isEventOccurrence } from "../components/arrangement/utils";
import { ResolvedDigitalLibraryService } from "../components/digitalLibrary/sanityQuery";

type BaseUrlDetails<T extends Pick<Documents, "_id" | "_type">, B extends keyof T> = Pick<T, "_id" | "_type" | B>;

type EventUrlDetails =
  | BaseUrlDetails<ResolvedEvent, "title" | "startDate">
  | BaseUrlDetails<NextEvent, "title" | "startDate" | "occurrence">;

type DigitalLibraryServiceUrlDetails = Pick<ResolvedDigitalLibraryService, "_id" | "_type"> &
  Pick<DigitalLibraryServiceTemplate, "slug">;

const isDigitalLibraryServiceUrlDetails = (doc: UrlDetails): doc is DigitalLibraryServiceUrlDetails =>
  doc._type.startsWith("digitalLibrary") && !doc._type.endsWith("Content");

type UrlDetails =
  | EventUrlDetails
  | BaseUrlDetails<Library, "slug">
  | BaseUrlDetails<Employee, "name">
  | BaseUrlDetails<List, "title">
  | BaseUrlDetails<Recommendation, "publication">
  | BaseUrlDetails<Service, "title">
  | BaseUrlDetails<StaticPage, "title">
  | DigitalLibraryServiceUrlDetails;

export const getDigitalLibraryTemplateServiceSlug = ({ slug }: Pick<DigitalLibraryServiceTemplate, "slug">) =>
  kebabCase(slug);

export const getDigitalLibraryServiceSlug = (digitalLibrary: DigitalLibraryServiceUrlDetails): string => {
  if (isDigitalLibraryCustomService(digitalLibrary)) {
    const slug = slugifyString(translate(digitalLibrary.name));
    return `${slug}_${digitalLibrary?._id}`;
  }

  if (!digitalLibrary?.slug) {
    throw new Error(`Could not generate path for digital library service ${JSON.stringify(digitalLibrary)}`);
  }

  return getDigitalLibraryTemplateServiceSlug(digitalLibrary);
};

export const getPath = (doc: UrlDetails) => {
  if (isDigitalLibraryServiceUrlDetails(doc)) {
    return `/digitalt-bibliotek/${getDigitalLibraryServiceSlug(doc)}`;
  }

  switch (doc._type) {
    case "event":
      return `/arrangementer/${getEventHumanReadablePart(doc)}_${
        isEventOccurrence(doc) ? doc?.occurrence?._key : doc?._id
      }`;
    case "library":
      return `/bibliotek/${doc?.slug}`;
    case "employee":
      return `/ansatte/${slugifyString(doc.name)}_${doc._id}`;
    case "list":
      return `/lister/${slugifyString(translate(doc.title))}_${doc._id}`;
    case "recommendation":
      return `/anbefalinger/${slugifyString(doc.publication?.title)}_${doc._id}`;
    case "service":
      return `/tjenester/${slugifyString(translate(doc.title))}_${doc._id}`;
    case "staticPage":
      return `/info/${slugifyString(translate(doc.title))}_${doc._id}`;
    default:
      // @ts-ignore
      const message = `Could not generate path for unkown document type: ${doc._type}`;
      console.error(message, { doc });
      throw new Error(message);
  }
};

// Denne tar ikke hensyn til gjeldende språk, men funker for øyeblikket fordi nettsteder kun har nynorsk ELLER bokmål. Om vi får støtte for at man kan begge deler samtidig bør denne kanskje ta hensyn til gjeldende språk.
const translate = (item?: LocalizedField<string>) => item?.nn ?? item?.nb;

const getEventHumanReadablePart = (event: EventUrlDetails) => {
  const title = slugifyString(translate(event.title));
  const date = formaterDato(isEventOccurrence(event) ? event.occurrence?.date : event.startDate, "dd-MMM").replace(
    ".",
    ""
  );

  if (!date) return title;

  return `${title}-${date}`;
};

export const extractId = (ctx: GetStaticPropsContext, paramKey: string): string => {
  if (!ctx.params) {
    throw new Error(`Could not extract url param for ${paramKey}`);
  }

  const temp = ctx.params[paramKey];

  if (typeof temp !== "string") {
    throw new Error(`Could not extract url param for ${paramKey}`);
  }

  return temp.split("_").at(-1) ?? "";
};
