import { Box, colors } from "@biblioteksentralen/js-utils";
import { ForwardedRef, forwardRef, ReactNode } from "react";

const PageHeaderComponent = ({ children }: { children: ReactNode }, ref: ForwardedRef<HTMLDivElement>) => (
  <Box ref={ref} as="header" borderBottom={`${colors.grey15} solid 0.15rem`}>
    {children}
  </Box>
);

export const PageHeader = forwardRef(PageHeaderComponent);
