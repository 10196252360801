import camelCase from "lodash/camelCase";
import {
  AdventCalendar,
  DigitalLibrary,
  DigitalLibraryServiceBibliofilApp,
  DigitalLibraryServiceCustom,
  Documents,
  Event,
  Library,
  Publication,
  SanityKeyed,
} from "./libryContentSanityTypes";
import { ArrayElement, NullableArrayElement, objectKeys } from "./typeUtils";

export type CustomizableRepeatedEventField = Extract<
  keyof Event,
  "eventStart" | "eventEnd" | "eventStatus" | "teaser" | "body" | "featured"
>;

export const customizableRepeatedEventFields: CustomizableRepeatedEventField[] = [
  "eventStart",
  "eventEnd",
  "eventStatus",
  "teaser",
  "body",
];

export type AdventCalendarDoor = ArrayElement<NonNullable<AdventCalendar["doors"]>>;

export type AdventCalendarDoorType = AdventCalendarDoor["_type"];

export const isPublication = (item: unknown): item is Publication =>
  !!item && typeof item === "object" && item?.["_type"] === "publication";

export const isLibrary = (item: unknown): item is Library =>
  !!item && typeof item === "object" && item?.["_type"] === "library";

export type DigitalLibraryService = NullableArrayElement<DigitalLibrary["services"]>;

export type DigitalLibraryServiceType = DigitalLibraryService["_type"];

const digitalLibraryServiceNameTypeHelper: Record<DigitalLibraryServiceType, true> = {
  digitalLibraryServiceCustom: true,
  digitalLibraryServiceBibliofilApp: true,
  digitalLibraryServiceBookbites: true,
  digitalLibraryServiceVerdensbiblioteket: true,
  digitalLibraryServiceDigitaltNasjonaltLanekort: true,
  digitalLibraryServiceBibliotekSok: true,
};

export const digitalLibraryServiceNames = objectKeys(digitalLibraryServiceNameTypeHelper);

export type DigitalLibraryServiceTemplate = Exclude<DigitalLibraryService, { _type: "digitalLibraryServiceCustom" }>;

export type DigitalLibraryServiceTemplateName = DigitalLibraryServiceTemplate["_type"];

export const digitalLibraryServiceTemplateNames: DigitalLibraryServiceTemplateName[] =
  digitalLibraryServiceNames.filter(
    (name): name is DigitalLibraryServiceTemplateName => name !== "digitalLibraryServiceCustom"
  );

export const isDigitalLibraryService = (item: unknown): item is DigitalLibraryService =>
  typeof item === "object" && digitalLibraryServiceNames.includes(item?.["_type"]);

export const isDigitalLibraryCustomService = (item: unknown): item is SanityKeyed<DigitalLibraryServiceCustom> =>
  typeof item === "object" && item?.["_type"] === "digitalLibraryServiceCustom";

type DigitalLibraryServiceTemplateSlug = NonNullable<DigitalLibraryServiceTemplate["slug"]>;

const digitalLibraryServiceTemplateSlugTypeHelper: Record<DigitalLibraryServiceTemplateSlug, true> = {
  bibliofilApp: true,
  bookbites: true,
  verdensbiblioteket: true,
  digitaltNasjonaltLanekort: true,
  bibliotekSok: true,
};

export const isDigitalLibraryServiceTemplateSlug = (item: unknown): item is DigitalLibraryServiceTemplateSlug =>
  typeof item === "string" && Object.keys(digitalLibraryServiceTemplateSlugTypeHelper).includes(camelCase(item));

type DigitalLibraryServiceTemplateContentName = `${DigitalLibraryServiceTemplateName}Content`;

export type DigitalLibraryServiceTemplateContent = Extract<
  Documents,
  { _type: DigitalLibraryServiceTemplateContentName }
>;

export const isBibliofilApp = (item: unknown): item is DigitalLibraryServiceBibliofilApp =>
  typeof item === "object" && item?.["_type"] === "digitalLibraryServiceBibliofilApp";
