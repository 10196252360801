const Sentry = require("@sentry/nextjs");

module.exports = {
  defaultNS: "common",
  pages: {
    // Since our volume of translations is not that high, and to simplify Poeditor setup, we use a single namespace
    "*": ["common"],
  },
  // Use "default" to redirect requests with no locale: https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
  defaultLocale: "default",
  locales: ["default", "nb", "nn"],
  // Use the ICU interpolation format rather than the default "{{}}" for interop with eslint-plugin-i18n-json
  interpolation: { prefix: "{", suffix: "}" },
  // We disallow nested translations with `keySeparator: false`, since we use natural language keys – this also simplifies some ts types
  keySeparator: false,
  // Use namespace separator "::" rather than the default ":", since the latter will be in use in natural language keys
  nsSeparator: "::",
  // With `allowEmptyStrings: false`, missing translations will fall back to the key rather than displaying empty
  allowEmptyStrings: false,
  logger: ({ i18nKey, namespace }) => {
    Sentry.captureException(new Error(`Missing translation ${namespace}:${i18nKey}`));
  },
};
