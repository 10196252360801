import { Link, LinkProps } from "@biblioteksentralen/js-utils";
import NextLink from "next/link";
import React, { AnchorHTMLAttributes } from "react";

type LinkComponentProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> & LinkProps;

interface InternalLinkProps extends LinkComponentProps {
  href: string;
  linkComponent?: (props: LinkComponentProps) => JSX.Element;
}

function InternalLink(props: InternalLinkProps) {
  const { href, linkComponent, ...rest } = props;
  const LinkComponent = linkComponent ?? Link;
  return (
    <NextLink href={href} passHref legacyBehavior>
      <LinkComponent {...rest} />
    </NextLink>
  );
}

export default InternalLink;
