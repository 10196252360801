import { extendTheme } from "@biblioteksentralen/js-utils";

// https://blog.logrocket.com/guide-css-word-wrap-overflow-wrap-word-break/
// https://medium.com/clear-left-thinking/all-you-need-to-know-about-hyphenation-in-css-2baee2d89179
// Skrur på hyphens auto som default fordi vi har masse mikrotekster og små elementer der layout knekker uten dette, og vi lager stadig nye og glemmer å ta hensyn til dette.
// Så kan man optionaly sette hyphens: none de stedene man ikke ønsker det, men har ikke sett noe som knekker/blir rart pga dette enda
const globalHyphens = {
  hyphens: "auto",
  hyphenateLimitChars: "8 5 3", // minimum word-length / min letters first line / min letters second line
};

export const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        ...globalHyphens,
      },
    },
  },
});
