// Source: https://stackoverflow.com/a/51399781/1540547
export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type NullableArrayElement<ArrayType extends readonly unknown[] | undefined | null> = ArrayElement<
  NonNullable<ArrayType>
>;

// https://stackoverflow.com/questions/41285211/overriding-interface-property-type-defined-in-typescript-d-ts-file
export type Modify<T, R> = Omit<T, keyof R> & R;

/**
 * Get the keys from an object, typing them in the process
 */
export const objectKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;
