import { LocalizedField } from "@libry-content/localization";
import { DigitalLibraryServiceCategory } from "@libry-content/types";

// The order is significant and determines the one in the frontend
export const digitalLibraryServiceCategoryNames: Record<DigitalLibraryServiceCategory, LocalizedField<string>> = {
  "library-app": {
    nb: "Bibliotekets app",
    nn: "Appen til biblioteket",
  },
  "ebooks-and-audiobooks": {
    nb: "E-bøker og lydbøker",
    nn: "E-bøker og lydbøker",
  },
  "film-services": {
    nb: "Filmtjenester",
    nn: "Filmtenester",
  },
  "music-and-podcasts": {
    nb: "Musikk og podkaster",
    nn: "Musikk og podkastar",
  },
  "newspapers-and-magazines": {
    nb: "Aviser og magasiner",
    nn: "Aviser og magasin",
  },
  "local-history-genealogy-and-archive": {
    nb: "Lokalhistorie, slektsgransking og arkiv",
    nn: "Lokalhistorie, slektsgransking og arkiv",
  },
  "other-digital-resources": {
    nb: "Andre digitale ressurser",
    nn: "Andre digitale ressursar",
  },
};
