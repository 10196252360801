import useSWR from "swr";
import { Role } from "@sanity/types";

import { sanityClient } from "../../utils/sanity/client";

interface CurrentSanityUser {
  id: string;
  name: string;
  email: string;
  profileImage: string | null;
  role: string;
  roles?: Role[];
}

const clientWithCredidentials = sanityClient.withConfig({
  withCredentials: true,
});

export function useSanityAuth() {
  const response = useSWR<CurrentSanityUser>("sanityAuthStatus", () => clientWithCredidentials.users.getById("me"));

  return {
    ...response.data,
    isAuthenticated: !!response.data?.id,
    logOut: () => clientWithCredidentials.auth.logout(),
    revalidate: response.mutate,
    loading: !response.data && !response.error,
    error: response.error,
  };
}
