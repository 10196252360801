import { localeFromLanguageCode, LanguageCode } from "@libry-content/localization";
import parse from "date-fns/parse";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";

/**
 * Format datestring of the form YYYY-MM-DD
 *
 * Since we store dates without time or timezone, we have to be careful not to get different dates
 * when the code runs on servers in other timezones (e.g. the google crawl bot). We want e.g.
 * 2022-11-17 to display as the 17th no matter where the code runs, therefore we format as UTC.
 */
export function formatDate(languageCode: LanguageCode, format = "Pp", date: string | undefined) {
  if (typeof date !== "string") return "Ukjent dato";

  if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) console.error("formatDate is meant to format dates on the form YYYY-MM-DD");

  const locale = localeFromLanguageCode[languageCode];

  try {
    // Note that although formatInTimeZone also accepts a string instead of a date object,
    // this would apply the users current timezone and give a wrong result.
    return formatInTimeZone(new Date(date), "UTC", format, { locale });
  } catch (err) {
    console.error("Could not format date:" + date, err);
    return date;
  }
}

export const isValidDate = (dateString: string, dateFormat: string) => {
  const parsed = parse(dateString, dateFormat, new Date());
  return parsed instanceof Date && !isNaN(parsed.getTime());
};
