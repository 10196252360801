import { isProduction } from "./isProduction";
import { Site } from "@libry-content/types";
import { LocalizedField, DEFAULT_LANGUAGE_CODE, LanguageCode } from "@libry-content/localization";
import { getPlatformUrl, platform } from "./platform";

export interface SpecialSite {
  _id: string;
  subdomain?: string;
  customDomain?: string;
  name: LocalizedField<string>;
  languages: LanguageCode[];
  defaultLanguage: LanguageCode;
}

export type SiteOrSpecialSite = Pick<Site, keyof SpecialSite> | SpecialSite;

export const ADMIN_SITE: SpecialSite = {
  _id: "sites.admin",
  name: {
    nb: "Adminsida",
  },
  subdomain: "admin",
  languages: [DEFAULT_LANGUAGE_CODE],
  defaultLanguage: DEFAULT_LANGUAGE_CODE,
};

export const SPLASH_SITE: SpecialSite = {
  _id: "sites.splash",
  name: {
    nb: "Splashsida",
  },
  customDomain: isProduction ? "www.librycontent.no" : platform.host,
  languages: [DEFAULT_LANGUAGE_CODE],
  defaultLanguage: DEFAULT_LANGUAGE_CODE,
};

const specialSiteIds = [ADMIN_SITE._id, SPLASH_SITE._id];

export const isNormalSite = (site: SiteOrSpecialSite | undefined): site is Site =>
  site?._id !== undefined && !specialSiteIds.includes(site._id);

const addDefaulLanguage = (site: SiteOrSpecialSite, skipLanguage: boolean, url: string): string =>
  skipLanguage || !site.defaultLanguage ? url : `${url}/${site.defaultLanguage}`;

/**
 * Get the base URL for a given site.
 *
 * @param site - Site instance.
 * @returns URL
 */
export const getSiteUrl = (site: SiteOrSpecialSite, { skipLanguage } = { skipLanguage: false }): string =>
  addDefaulLanguage(
    site,
    skipLanguage,
    site?.customDomain ? `https://${site.customDomain}` : getPlatformUrl(site.subdomain)
  );

export const splashSiteUrl = `https://${SPLASH_SITE.customDomain}/${DEFAULT_LANGUAGE_CODE}`;
