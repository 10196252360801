import { usePrevious } from "react-use";
import { ReactNode, useEffect, useState } from "react";
import { usePreviewContext } from "./previewContext";
import { Heading, Text } from "@chakra-ui/react";
import { Site } from "@libry-content/types";
import { colors, Flex, HashLinkTarget, Spinner, Box, Container, Image, Stack } from "@biblioteksentralen/js-utils";
import { useRouter } from "next/router";
import { css } from "@emotion/react";
import { nanoid } from "nanoid";
import { getTitle } from "../SEO";
import { fetchWithCommonDataAndCurrentSiteFilter } from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";
import { useTranslation } from "../../utils/hooks/useTranslation";

export const somePreviewID = nanoid();
interface Metadata {
  image?: string;
  title?: string;
  host?: string;
  description?: string;
}

// While loading the Image will have a height, on top of which we can put the placeholder with `position: absolute`.
// After a failure the Image has no height and we use `position: relative`.
const ImagePlaceholder = ({ children, loading }: { children: ReactNode; loading?: boolean }) => (
  <Flex
    position={loading ? "absolute" : "relative"}
    left="0"
    top="0"
    width="100%"
    height="100%"
    opacity="0.9"
    padding="3rem 2rem"
    justifyContent="center"
    alignItems="center"
    borderRadius="0.25rem"
    background={colors.grey05}
  >
    {children}
  </Flex>
);

export function SOMEPreview() {
  const { t, ts } = useTranslation();
  const previewContext = usePreviewContext();
  const [metadata, setMetadata] = useState<Metadata | undefined>();
  const [site, setSite] = useState<Site | undefined>(undefined);
  const router = useRouter();
  const [imageStatus, setImageStatus] = useState<"loading" | "loaded" | "failed">("loading");
  const previousImage = usePrevious(metadata?.image);

  useEffect(() => {
    if (!previewContext.isOn) return;
    const updateMetadata = () => setMetadata(getMetdata());
    updateMetadata();
    const interval = setInterval(updateMetadata, 5000);
    return () => clearInterval(interval);
  }, [router.asPath, previewContext.isOn]);

  useEffect(() => {
    const siteDomain = metadata?.host?.split(":")?.[0];
    if (!siteDomain) return;

    fetchWithCommonDataAndCurrentSiteFilter(`{}`, { siteDomain })
      .then((response) => setSite(response?.data?.commonData.site))
      .catch(console.error);
  }, [metadata?.host]);

  useEffect(() => {
    if (metadata?.image && previousImage !== metadata?.image) {
      setImageStatus("loading");
    }
  }, [previousImage, metadata?.image]);

  useEffect(() => {
    if (imageStatus === "loading" && !metadata?.image) setImageStatus("loaded");
  }, [imageStatus, metadata?.image]);

  if (!previewContext.isOn) return null;

  const haveImage = !!metadata?.image;

  return (
    <Box borderTop={`solid ${colors.lightPeach} .2rem`} backgroundColor={colors.alertYellow}>
      <Container maxW="container.lg" padding="4rem 1rem">
        <Heading as="h2" fontSize="lg">
          <HashLinkTarget id={somePreviewID} />
          {t("Forhåndsvisning ved deling i sosiale medier")}
        </Heading>
        <Text fontSize=".9rem" opacity=".8">
          {t(
            "Facebook og andre sosiale medier styrer selv hvordan layout på forhåndsvisning blir, men sannsynligvis vil det se omtrent slik ut:"
          )}
        </Text>
        <Stack
          maxW="35rem"
          marginY="2rem"
          backgroundColor="white"
          boxShadow="0 .5rem 1rem #8884"
          borderRadius="md"
          padding="1rem"
          justifyContent="space-between"
        >
          <Box position="relative">
            {haveImage && (
              <Image
                onError={() => {
                  setImageStatus("failed");
                }}
                onLoad={(evt) => {
                  setImageStatus("loaded");
                }}
                borderRadius="md"
                objectFit="cover"
                css={css`
                  aspect-ratio: 1.9/1;
                `}
                src={metadata?.image}
                alt=""
              />
            )}
            {haveImage && imageStatus === "loading" && (
              <ImagePlaceholder loading>
                <Spinner />
              </ImagePlaceholder>
            )}
            {haveImage && imageStatus === "failed" && (
              <ImagePlaceholder>
                {"⚠️ "}
                {t("Bilde ikke tilgjengelig")}
              </ImagePlaceholder>
            )}
          </Box>
          <Box>
            <Text fontWeight={600}>{metadata?.host}</Text>
            {!!metadata?.title && (
              <Heading as="h3" fontSize="md" color="blue.600">
                {getTitle(metadata.title, ts(site?.name))}
              </Heading>
            )}
            <Text noOfLines={2}>{metadata?.description}</Text>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

// Henter ut data på denne måten fordi vi prøver å simulere hvordan Facebook/google etc henter ut metadata fra sidene våre
function getMetdata(): Metadata {
  const title = document.querySelector("title")?.innerText.split("|")[0]; // henter data fra <title>Min tittel</title>
  // @ts-ignore
  const description = document.querySelector('meta[name="description"]')?.content; // henter data fra <meta name="description" content="Min beskrivelse"/>
  // @ts-ignore
  const image = document.querySelector('meta[property="image"]')?.content; // henter data fra <meta property="image" content="https://myimage.jpg" />
  // @ts-ignore
  const canonical = document.querySelector('link[rel="canonical"]')?.href; // henter data fra <link rel="canonical" href="https://gjeldendeUrl" />

  return { description, image, title, host: getHost(canonical) };
}

function getHost(url?: string) {
  try {
    return new URL(url || "").host;
  } catch {
    return "";
  }
}
